:global(#leftVideo)::-webkit-media-controls {
  display: none !important;
}

.videoContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #222222;
  border-radius: 5px;
}

.videoNoContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #222222;
  border-radius: 5px;
  padding-top: 50px;
}

.buttonsetting {
  padding: 13px 26px !important;
  border-radius: 50px !important;
  background: #272b30 !important;
  color: #828282 !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
}

.activeButton {
  background: #ae8a27 !important;
  color: #ffffff !important;
}

.centerimg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.centerimg img {
  padding-bottom: 40px;
}

.textContent {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 2px;
  color: #ffffff;
}

/* .videoNoContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #222222;
} */

.playerContainer {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Making this larger causes issues on mobile */
  min-height: 48vh;
}

@media only screen and (max-width: 600px) {
  .mobileStack {
    flex-direction: column;
    width: 100%;
    gap: 4px;
  }
}

.fullHeightColumn.fullHeightColumn.fullHeightColumn {
  height: calc(100vh - 62px);
  transition: width ease-out 0.5s;
}

.fullHeightColumnFullscreen.fullHeightColumnFullscreen.fullHeightColumnFullscreen {
  height: calc(100vh);
}
