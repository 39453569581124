.userMenuHeader {
  background: rgba(0, 0, 0, 0.25);
  color: rgb(255, 255, 255);
  padding: 5px;
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.userMenuContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.userMenuContent button {
  padding: 5px;
  background: transparent;
  border: none;
  color: aliceblue;
  text-align: left;
  cursor: pointer;
}

.userMenuContent button:hover {
  color: #27ae60;
}

:global(.ui.popup).userMenu {
  background: #181818 !important;
  border-color: #27ae60 !important;
  padding: 0px !important;
  border-radius: 10px !important;
  padding: 10px !important;
}

:global(.ui.popup).userMenu:before {
  display: none !important;
}
