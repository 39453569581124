html body {
  margin: 0;
  /* fix for scrollbar popping in and out at certain widths, but adds a permanent scrollbar */
  overflow-y: scroll;
  background-color: #040404;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

/* If a style is used outside of App, put it here */
button.linkButton {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  /* make the font match the rest of the text */
  font-family: inherit;
}

.footerIcon {
  color: white;
  margin-right: 10px;
}

.toolButton {
  min-width: 135px;
  height: 36px;
}

.screenshareButton {
  background: #27ae60 !important;
  border-radius: 8px !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  width: 100% !important;
  color: #ffffff !important;
  margin-bottom: 87px !important;
}

.screenstopButton {
  background: transparent !important;
  border-radius: 8px !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  width: 100% !important;
  color: #ff5b5b !important;
  margin-bottom: 87px !important;
  border: 1px solid #ff5b5b !important;
}

.smallText {
  font-size: 12px;
}

.sectionHeader {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bolder;
  color: gray;
  margin-top: 8px;
}

.newsectionHeader {
  margin-top: 30px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: #ae8a27;
  margin-bottom: 24px;
}

.chatHeader {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16.6076px;
  line-height: 20px;
  letter-spacing: 0.124557px;
  color: rgba(245, 249, 255, 0.95);
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}

.chatHeader:hover {
  color: #27ae60;
}

.activeTab {
  color: #27ae60;
}

.partylink {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: #ae8a27;
  margin-bottom: 20px;
}

.description {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #828282;
}

.toggleText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.toggleText .ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  height: 2rem !important;
}

.toggleText .ui.toggle.checkbox input:checked ~ .box:after,
.ui.toggle.checkbox input:checked ~ label:after {
  left: 1.8rem !important;
}

.toggleText .ui.toggle.checkbox .box:after,
.ui.toggle.checkbox label:after {
  top: 3px !important;
}

.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  background-color: #828282 !important;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ .coloring.red:before {
  background: #f25f5c !important;
}

/* .ui.toggle.checkbox input:checked~label:before {
  background-color: red !important
} */

.toggleText .ui.toggle.checkbox .box:after,
.ui.toggle.checkbox label:after {
  background-color: #040404 !important;
}

.toggleText .ui.toggle.checkbox input ~ .box:after,
.ui.toggle.checkbox input ~ label:after {
  left: 0.3rem !important;
}

.mb-28 {
  margin-bottom: 28px;
}

.mb-26 {
  margin-bottom: 28px;
}

.mb-34 {
  margin-bottom: 34px;
}

.m-20 {
  margin-bottom: 20px;
}

.m-40 {
  margin-bottom: 40px;
}

.m-65 {
  margin-bottom: 20px;
}

.w-100 {
  width: 100%;
}

.titleurl {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.1px;
}

.nowwatching {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.copysharebutton .copybutton {
  background: #27ae60 !important;
  border-radius: 8px !important;
  padding: 20px !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  color: #ffffff !important;
}

.topcopybutton {
  background: #27ae60 !important;
  border-radius: 8px !important;
  padding: 15px 20px !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  color: #ffffff !important;
}

.copysharebutton .sharebutton {
  /* Primary */

  border: 1px solid #27ae60 !important;
  border-radius: 8px !important;
  background: transparent !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #27ae60 !important;
}

.mr-14 {
  margin-right: 14px !important;
}

/* Override default semantic ui styles below */
.ui .comment {
  word-break: break-word;
}

.ui.labeled.icon.button > .icon.loading,
.ui.labeled.icon.buttons > .button > .icon.loading {
  background-color: initial;
}

.ui.dropdown .menu[role='listbox'] {
  z-index: 1001;
}

.ui.selection.dropdown.ui.selection.dropdown {
  min-height: 1em;
  border: 0px;
}

.ui.search.dropdown > .text {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.ui.dropdown > .text {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.ui.inverted.menu .active.item {
  border-bottom: 2px solid #2185d0;
  background: inherit;
  margin-bottom: -2px;
}

.comboxurl input {
  background: #040404 !important;
  border: 1px solid #272a2f !important;
  padding: 15px !important;
  border-radius: 10px !important;
}

.comboxurl input[type='text']:focus {
  background: #040404 !important;
}

.chatmessage input {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  /* identical to box height, or 143% */
  letter-spacing: 0.207595px !important;
  /* Text and Icon/Disabled */
  color: rgba(195, 208, 229, 0.5) !important;
  background: #1e232a !important;
  padding: 15px !important;
  border-radius: 7px !important;
}

.chatmessage input[type='text']:focus {
  background: #1e232a !important;
  color: rgba(195, 208, 229, 0.5) !important;
}

.chatmessage .ui.icon.input > i.circular.icon {
  background: transparent !important;
}

.chatmessage .imgbox {
  right: 10px;
  top: 17px;
  bottom: 0;
  margin: 0 auto;
  position: absolute;
}

.chatmessage .imgbox img {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.popupdes {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  /* or 167% */

  letter-spacing: 1px;

  /* Grey */

  color: #828282;
}

.headercolor {
  background: #272b30 !important;
}

.headercolor .spandesc {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 33px !important;
  /* identical to box height */

  letter-spacing: 2px !important;

  color: #ffffff !important;
}

.headercolor .buttonfooter {
  background: linear-gradient(
    90deg,
    #b921e6 -147.46%,
    #9366f5 -64.09%,
    #3da0f1 24.34%,
    #68dbca 105.18%
  ) !important;
  border-radius: 8px !important;

  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 26px !important;
  /* or 162% */

  text-transform: uppercase !important;
  margin-left: 0 !important;

  /* BG */

  color: #040404 !important;
}

.borderset {
  border-bottom: 1px solid #707070 !important;
}

.mainmodel {
  box-shadow: 0px 4px 4px #673ab7 !important;
}

/* .headercolor .ui.modal .actions>.button {} */
.buttonsvg {
  display: flex !important;
  align-items: center !important;
}
.buttonsvg img {
  width: 30px;
  margin-right: 5px;
}

.message-reply {
  background-color: #282b30;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  margin-bottom: -5px;
}

.message-reply > div {
  background-color: #060606;
  border-radius: 5px;
  padding: 10px;
  position: relative;
}

.message-reply > div button {
  background-color: #282b30;
  border: none;
  border-radius: 100px;
  font-size: 11px;
  color: #fff;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 999;
  cursor: pointer;
  height: 25px;
  width: 25px;
  display: grid;
  place-items: center;
}

.message-reply > div p {
  color: #fff;
  font-size: 13px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.main-message-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.reply-button {
  background: none;
  border: none;
  padding: 0;
  color: grey;
  cursor: pointer;
}

.reply-box {
  background: #464646;
  border-radius: 5px;
  width: calc(100% + 15px);
  padding: 8px;
}

.reply-box:hover {
  cursor: pointer;
}

.reply-box h6 {
  font-size: 13px;
  margin: 0;
  color: #dcdcdc;
  font-weight: 400;
  margin-bottom: 5px;
}

.reply-box p {
  font-size: 12px;
  margin: 0;
  color: #dcdcdc;
  font-weight: 400;
}

.gradient-modal {
  border: 1px solid transparent !important;
  background: linear-gradient(#1d0232, #1d0232),
    linear-gradient(
      to right bottom,
      #0feffd 0%,
      #ff00f5 52%,
      rgba(15, 239, 253, 0) 78%
    ) !important;
  background-origin: border-box !important;
  background-clip: padding-box, border-box !important;
}

.gradient-modal .gradient-outline-btn {
  background: linear-gradient(
    45deg,
    #7b55fe 0%,
    #da7aff 52%,
    #ff26e6 78%
  ) !important;
  position: relative;
  z-index: 0;
}
.gradient-modal .gradient-outline-btn::after {
  position: absolute;
  content: '';
  left: 1px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  z-index: -1;
  border-radius: inherit;
  margin: auto;
  background: #1d0232;
}
.gradient-modal .gradient-outline-btn span {
  background: linear-gradient(45deg, #7b55fe 0%, #da7aff 52%, #ff26e6 78%);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.gradient-modal .gradient-btn {
  background: linear-gradient(
    45deg,
    #7b55fe 0%,
    #da7aff 52%,
    #ff26e6 78%
  ) !important;
  border: none;
  color: #33333d !important;
}
.gradient-modal .ui.button.gradient-btn:disabled,
.gradient-modal .ui.disabled.button {
  background: linear-gradient(
    45deg,
    #7b55fe 0%,
    #da7aff 52%,
    #ff26e6 78%
  ) !important;
  border: none;
  color: #33333d !important;
}

.gradient-modal .ui.selection.visible.dropdown > .text:not(.default) {
  color: rgb(255 255 255);
}

.gradient-modal .ui.dropdown .menu {
  background: #1d0532 !important;
}
.gradient-modal .ui.selection.active.dropdown .menu {
  border-color: #f626e6 !important;
}
.gradient-modal .ui.selection.dropdown .menu > .item {
  border: none !important;
}
