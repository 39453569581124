.subscriber {
  position: relative;
}

.subscriber img {
  border: 3px solid transparent;
}

.mainListDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  margin: 10px 0;
}

.listInnerDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
}

.listInnerDiv > div > div {
  height: 100%;
}

.chatContainer {
  flex-grow: 1;
  overflow: auto;
}

.light.light.light.light.light {
  color: gainsboro;
}

.light.light.light.light.light.emoji {
  font-size: 24px;
}

.descText,
.dark {
  color: #828282 !important;
}

.system.system.system.system.system {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.comment {
  width: 100%;
  display: flex;
  gap: 10px;
}

.comment .commentMenu {
  display: none;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: -13px;
  padding: 4px !important;
  border-radius: 3px;
  background: rgb(146, 146, 146);
  cursor: pointer;
}

.comment .commentMenu:hover,
.comment.selected .commentMenu {
  background: rgb(46, 137, 212);
  transition: background 0.2s ease-in-out;
}

/* .comment:hover,
.comment.selected {
  border-radius: 5px;
  background: radial-gradient(
    at top right,
    #7eaee02a,
    transparent 70%
  ) !important;
} */

.comment:hover .commentMenu,
.comment.selected .commentMenu {
  display: flex;
}

.reactionMenuContainer {
  display: flex;
  flex-wrap: nowrap;
  border-radius: 5px;
  position: fixed;
  font-size: 24px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.91);
}

.reactionMenuContainer div {
  line-height: 30px;
  padding: 5px;
}

.reactionMenuContainer div:hover {
  background-color: rgb(46, 137, 212);
  transition: background-color 0.2s ease-in-out;
}

.reactionContainer {
  display: inline-flex;
  align-items: center;
  padding: 3px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.16);
  margin-right: 3px;
  margin-top: 1px;
  min-width: 39px;
  cursor: pointer;
}

.reactionContainer.highlighted {
  background: rgb(66, 111, 129);
}

.reactionContainer:hover {
  background-color: rgb(46, 137, 212);
  transition: background-color 0.2s ease-in-out;
}

.reaction-enter {
  transform: scale(0);
}

.reaction-enter-active {
  transform: scale(1);
  transition: all 200ms ease-in !important;
}

.reaction-exit {
  transform: scale(1);
}

.reaction-exit-active {
  transform: scale(0);
  transition: all 200ms ease-in !important;
}

.reactionCounter-enter {
  transform: scale(0);
  color: rgb(255, 136, 0) !important;
}

.reactionCounter-enter-active {
  transform: scale(1);
  transition: all 200ms ease-in !important;
}

.reactionCounter-exit {
  transform: scale(1);
  color: rgb(255, 136, 0) !important;
}

.reactionCounter-exit-active {
  transform: scale(0);
  transition: all 200ms ease-in !important;
}

.reactionMenu-enter {
  transform: scale(0);
}

.reactionMenu-enter-active {
  transform: scale(1);
  transition: all 100ms ease-out !important;
}

.reactionMenu-exit {
  transform: scale(1);
}

.reactionMenu-exit-active {
  transform: scale(0);
  transition: all 100ms ease-out !important;
}

.chatSelect {
  background: transparent !important;
  border: 1px solid #272b30 !important;
  color: #fff !important;
  padding: 15px !important;
  border-radius: 12px !important;
}

.messageBoxChat {
  display: flex;
  align-items: center;
  background: #272b30;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: rgba(224, 236, 255, 0.8);
  padding: 15px 15px;
  align-items: center;
  border-radius: 7px;
}

/* .ui.icon.input > input.custom-input {
  padding-right: 4.67em !important;
} */

.chatBox {
  max-width: calc(100% - 50px);
  display: flex;
  align-items: flex-start;
  border-radius: 12px;
  background: #191919;
  padding: 8px 16px;
  gap: 10px;
}

.moreBtn {
  background: transparent;
  border: none;
  padding: 0px !important;
  margin: 0px !important;
  color: #fff;
  cursor: pointer;
}

.moreBtn i {
  width: auto !important;
  margin: 0 !important;
}

.avatarImage {
  width: 40px !important;
  height: 40px !important;
  border-radius: 100px !important;
  overflow: hidden;
}

.mainChatBox {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  gap: 10px;
}

.profileShield {
  position: relative;
  width: 40px;
  height: 40px;
}

.profileShield img {
  border: 2px solid #27ae60;
  border-radius: 100px !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profileShield .shieldDiv {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -2px;
  top: -2px;
}

.profileShield .shieldDiv::after {
  content: '10K';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 6px;
  font-family: 'Poppins', sans-serif;
  -webkit-text-stroke: 0 #fff;
  font-weight: 700;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 100px;
  justify-content: center;
  padding: 2px;
  color: #000;
  height: 9px;
}

.ui.icon.input > input {
  padding-right: 4.5em;
}
